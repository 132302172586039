<arc-container #container theme="light">
  <ng-container *ngIf="vm$ | async as vm">
    <arc-navbar #navbar home="/" slot="nav">
      <ng-container *ngIf="vm.authenticated && !vm.maintenance">
        <arc-icon-button
          name="grid"
          label="Collection"
          routerLink="collection"
          tippy="Collection"
        ></arc-icon-button>
        <arc-icon-button
          name="chart"
          label="Project Dashboards"
          tippy="Project Dashbaords"
          [matMenuTriggerFor]="projectDashboardMenu"
        ></arc-icon-button>
        <arc-icon-button
          name="question-circle"
          label="Support"
          tippy="Support"
          [matMenuTriggerFor]="platformSupportMenu"
        ></arc-icon-button>
        <mat-menu #projectDashboardMenu>
          <button
            mat-menu-item
            aria-label="Project Progress Dashboard"
            routerLink="project/progress"
          >
            Project Progress Dashboard
          </button>
          <button
            *ngIf="vm.userCanManageProjects"
            mat-menu-item
            aria-label="Project Selection Dashboard"
            routerLink="project/selection"
          >
            Project Selection Dashboard
          </button>
          <button mat-menu-item aria-label="Analytics Dashboard">
            <zero-external-link href="https://analytics.zero.arup.com/"
              >Analytics Dashboard</zero-external-link
            >
          </button>
        </mat-menu>
        <mat-menu #platformSupportMenu>
          <button mat-menu-item aria-label="Documentation Site">
            <zero-external-link href="https://docs.zero.arup.com"
              >Docs Site</zero-external-link
            >
          </button>
          <button mat-menu-item aria-label="Moodle Course">
            <zero-external-link
              href="https://moodle.arup.com/course/view.php?id=6971"
            >
              Moodle Course
            </zero-external-link>
          </button>
          <button mat-menu-item aria-label="Forum">
            <zero-external-link
              href="https://forums.intranet.arup.com/?CFApp=319"
              >Forum</zero-external-link
            >
          </button>
          <button mat-menu-item aria-label="Regional Skills Coaches">
            <zero-external-link
              href="https://docs.zero.arup.com/support/support.html#regional-skills-coaches"
              >Regional Skills Coaches</zero-external-link
            >
          </button>
          <button mat-menu-item aria-label="Feedback Hub">
            <zero-external-link href="https://feedback.arup.com/zero/52"
              >Feedback Hub</zero-external-link
            >
          </button>
          <button mat-menu-item aria-label="Veracity">
            <zero-external-link href="https://veracity.arup.com"
              >Veracity</zero-external-link
            >
          </button>
        </mat-menu>
      </ng-container>
      <mgt-login slot="user"></mgt-login>
    </arc-navbar>
    <div *ngIf="!vm.isIframe" class="zero-vertical-scroll">
      <zero-maintenance-page
        *ngIf="vm.maintenance; else app"
      ></zero-maintenance-page>
      <ng-template #app>
        <zero-small-screen *ngIf="vm.smallScreen"></zero-small-screen>
        <div
          style="height: 100%"
          [style.display]="vm.smallScreen ? 'none' : null"
        >
          <router-outlet></router-outlet>
        </div>
      </ng-template>
    </div>
  </ng-container>
</arc-container>
